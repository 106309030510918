
import { defineComponent } from "vue";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "AccountIsPrivateBanner",
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  emits: {
    onClose: () => true,
  },
  setup(_, { emit }) {
    const onClose = () => emit("onClose");

    return { onClose, RouteName };
  },
});
