<template>
  <div :id="dropDownID">
    <button
      @click="dropdownOpen = !dropdownOpen"
      class="relative rounded-md bg-white p-2 focus:outline-none inline-flex items-center group"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 text-gray-500 group-hover:text-gray-900 transition duration-300 ease-in-out mr-1.5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span
        class="hidden xl:flex text-sm text-gray-800 group-hover:text-black transition duration-300 ease-in-out font-normal"
      >
        {{ currentUser?.email }}
      </span>
      <svg
        class="h-6 w-6 xl:h-5 xl:w-5 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <div
      v-show="dropdownOpen"
      class="absolute right-0 mt-2 py-2 w-48 space-y-1 border bg-white rounded-md shadow-xl transform -translate-y-2 -translate-x-10"
      @click="dropdownOpen = false"
    >
      <router-link
        :to="{ name: RouteName.UPLOAD_LOGO, params: { municipalitySlug } }"
        class="block px-4 py-2 text-sm text-gray-700 hover:text-gray-800 hover:bg-gray-200 transition duration-300 ease-in-out"
      >
        Lae logo üles
      </router-link>
      <router-link
        :to="{ name: RouteName.UPLOAD_CSV, params: { municipalitySlug } }"
        class="block px-4 py-2 text-sm text-gray-700 hover:text-gray-800 hover:bg-gray-200 transition duration-300 ease-in-out"
      >
        Lae andmed üles
      </router-link>
       <router-link
        :to="{ name: RouteName.JUHENDID, params: { municipalitySlug } }"
        class="block px-4 py-2 text-sm text-gray-700 hover:text-gray-800 hover:bg-gray-200 transition duration-300 ease-in-out"
      >
        Juhendid
      </router-link>
       <router-link
        :to="{ name: RouteName.KONTAKT, params: { municipalitySlug } }"
        class="block px-4 py-2 text-sm text-gray-700 hover:text-gray-800 hover:bg-gray-200 transition duration-300 ease-in-out"
      >
        Kontakt
      </router-link>

      <div class="px-3 pt-3 pb-1 border-t">
        <CustomButton @click="logout" class="block w-full">
          Logi välja
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { currentUser } from "@/firebase/firebase";
import { RouteName } from "@/router/types";
import { useUserStore } from "@/store/UserStore";
import CustomButton from "../basic/CustomButton.vue";

export default defineComponent({
  components: { CustomButton },
  setup() {
    const dropdownOpen = ref(false);
    const dropDownID = ref("dropDown");
    const userStore = useUserStore();
    const userState = userStore.getState();
    const municipalitySlug = computed(
      () => userState.userInfo?.municipalitySlug
    );

    const logout = () => userStore.logOut();
    onMounted(() =>
      window.addEventListener("click", (e: MouseEvent) => {
        if (dropdownOpen.value) {
          const target = e.target as HTMLDivElement;
          if (!target?.closest(`#${dropDownID.value}`))
            dropdownOpen.value = false;
        }
      })
    );

    onBeforeUnmount(() =>
      window.removeEventListener("click", (e: MouseEvent) => {
        if (dropdownOpen.value) {
          const target = e.target as HTMLDivElement;
          if (!target?.closest(`#${dropDownID.value}`))
            dropdownOpen.value = false;
        }
      })
    );

    return {
      dropDownID,
      dropdownOpen,
      logout,
      currentUser,
      RouteName,
      municipalitySlug,
    };
  },
});
</script>
