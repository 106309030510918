<template>
  <div class="self-center flex-shrink-0">
    <img
      v-if="logoURL"
      :src="logoURL"
      alt=""
      class="h-10 sm:h-12 2xl:h-14 w-auto cursor-pointer"
      @click="toKoondVaade"
    />
    <div v-else class="self-center h-12 w-36">
      <div
        class="flex items-center justify-center h-full bg-gray-100 rounded-md text-sm text-gray-400 cursor-pointer"
        @click="toKoondVaade"
      >
        LOGO PUUDUB
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent} from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipalitySlug = computed(
      () => municipalityState.municipality?.uid
    );
    const koondVaadePath = computed(
      () => `/${municipalitySlug.value}/koondvaade`
    );

    const toKoondVaade = () => router.push({ path: koondVaadePath.value });

    const logoURL = computed(() => municipalityState.logoUrl);
    return { koondVaadePath, logoURL, toKoondVaade };
  },
});
</script>
