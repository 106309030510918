
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { currentUser } from "@/firebase/firebase";
import { RouteName } from "@/router/types";
import { useUserStore } from "@/store/UserStore";
import CustomButton from "../basic/CustomButton.vue";

export default defineComponent({
  components: { CustomButton },
  setup() {
    const dropdownOpen = ref(false);
    const dropDownID = ref("dropDown");
    const userStore = useUserStore();
    const userState = userStore.getState();
    const municipalitySlug = computed(
      () => userState.userInfo?.municipalitySlug
    );

    const logout = () => userStore.logOut();
    onMounted(() =>
      window.addEventListener("click", (e: MouseEvent) => {
        if (dropdownOpen.value) {
          const target = e.target as HTMLDivElement;
          if (!target?.closest(`#${dropDownID.value}`))
            dropdownOpen.value = false;
        }
      })
    );

    onBeforeUnmount(() =>
      window.removeEventListener("click", (e: MouseEvent) => {
        if (dropdownOpen.value) {
          const target = e.target as HTMLDivElement;
          if (!target?.closest(`#${dropDownID.value}`))
            dropdownOpen.value = false;
        }
      })
    );

    return {
      dropDownID,
      dropdownOpen,
      logout,
      currentUser,
      RouteName,
      municipalitySlug,
    };
  },
});
