
import {
  computed,
  defineComponent,
  onUnmounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import BasicLayout from "@/components/layout/BasicLayout.vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { useRoute } from "vue-router";
import AccountIsPrivateBanner from "@/components/common/AccountIsPrivateBanner.vue";
import { useUserStore } from "@/store/UserStore";
import { RouteName } from "@/router/types";

export default defineComponent({
  components: {
    AccountIsPrivateBanner,
    BasicLayout,
  },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipality = computed(() => municipalityState.municipality);
    const isSubscribed = computed(() => municipality.value?.subscribed);
    const route = useRoute();
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const isPrivateAccount = ref(false);
    const isBannerClosed = ref(false);
    const userSlug = computed(() => user.value?.municipalitySlug);
    const shouldShowPrivateAccountBanner = computed(
      () =>
        !!user.value &&
        !isSubscribed.value &&
        user.value?.municipalitySlug === route.params.municipalitySlug &&
        route.name !== RouteName.LIITUMISAVALDUS
    );
    watchEffect(
      () => (isPrivateAccount.value = shouldShowPrivateAccountBanner.value)
    );

    const showBanner = computed(
      () => isPrivateAccount.value && !isBannerClosed.value
    );

    watch(
      () => route.params.municipalitySlug,
      (newValue, oldValue) => {
        if (newValue !== oldValue)
          municipalityStore.setMunicipality(newValue as string);
      },
      { immediate: true }
    );

    onUnmounted(() => municipalityStore.resetState());

    return { userSlug, showBanner, isBannerClosed };
  },
});
