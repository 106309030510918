<template>
  <div class="w-full min-w-3/4-screen mx-auto">
    <NoSuchMunicipality v-if="noMunicipality" />
    <NotRegistredMunicipality v-else-if="notRegistredMunicipality"/>
    <div v-else-if="currentMunicipality">
      <NavBar />
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import NavBar from "@/components/layout/navbar/NavBar.vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import NoSuchMunicipality from "@/components/common/NoSuchMunicipality.vue";
import NotRegistredMunicipality from "@/components/common/NotRegistredMunicipality.vue";

export default defineComponent({
  components: {
    NotRegistredMunicipality,
    NoSuchMunicipality,
    NavBar,
  },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const currentMunicipality = computed(() => municipalityState.municipality);
    const municipalityLoaded = computed(
      () => municipalityState.municipalityLoaded
    );
    const notRegistredMunicipality = computed(
      () => municipalityLoaded.value && !currentMunicipality.value?.active
    );
    const noMunicipality = computed(
      () => !currentMunicipality.value && municipalityLoaded.value
    );
    return { currentMunicipality, noMunicipality, notRegistredMunicipality };
  },
});
</script>
