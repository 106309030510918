<template>
  <div class="inline-flex items-center space-x-2">
    <router-link :to="{ name: RouteName.HOME }" class="cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 flex-shrink-0 text-gray-500 hover:text-gray-900"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      </svg>
    </router-link>
    <NavBarLoggedInUserAuthLinks v-if="currentUser" />
    <NavBarLoggedOutAuthLinks v-else />

    <div class="flex items-center xl:hidden">
      <button
        @click="toggleMobileMenu"
        type="button"
        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none border:none transition duration-300 ease-in-out"
        aria-controls="mobile-menu"
        aria-expanded="false"
      >
        <span class="sr-only">Menüü</span>
        <svg
          class="h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            v-if="isMobileMenuOpen"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M6 18L18 6M6 6l12 12"
          />
          <path
            v-else
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { currentUser } from "@/firebase/firebase";
import NavBarLoggedInUserAuthLinks from "@/components/layout/navbar/authlinks/NavBarLoggedInUserAuthLinks.vue";
import NavBarLoggedOutAuthLinks from "@/components/layout/navbar/authlinks/NavBarLoggedOutAuthLinks.vue";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "NavBarAuthLinks",
  components: { NavBarLoggedOutAuthLinks, NavBarLoggedInUserAuthLinks },
  props: {
    isMobileMenuOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    onMobileMenuToggle: (state: unknown) => typeof state === "boolean",
  },
  setup(props, { emit }) {
    const toggleMobileMenu = () =>
      emit("onMobileMenuToggle", !props.isMobileMenuOpen);

    return { currentUser, toggleMobileMenu, RouteName };
  },
});
</script>

<style scoped></style>
