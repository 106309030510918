<template>
  <svg class="h-9 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.17 226.79">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Icon_Set" data-name="Icon Set">
        <polyline
          class="cls-1"
          points="193.75 47.55 193.75 39.71 193.75 2.5 145.94 2.5 160.18 16.73 119.17 57.74 80.25 18.83 62.03 37.05"
        />
        <polyline
          class="cls-1"
          points="168.86 48.82 180.56 37.12 185.91 42.47"
        />
        <polyline
          class="cls-1"
          points="62.03 37.05 2.5 96.58 22.88 116.96 80.25 59.6 119.17 98.51 152.17 65.5"
        />
        <polyline
          class="cls-1"
          points="164.51 224.29 164.51 59.73 213.67 59.73 213.67 224.29"
        />
        <polyline
          class="cls-1"
          points="115.36 223.67 115.36 114.22 164.51 114.22"
        />
        <polyline
          class="cls-1"
          points="115.36 157.12 66.21 157.12 66.21 223.66"
        />
        <polyline
          class="cls-1"
          points="66.21 190.39 17.06 190.39 17.06 223.66"
        />
        <path
          class="cls-2"
          d="M174.52,69.73v44.43a34.79,34.79,0,0,1,29.15,0V69.73Z"
        />
        <path
          class="cls-3"
          d="M125.36,125.23v44.43a34.82,34.82,0,0,1,29.16,0V125.23Z"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InvesteeringudIcon",
  setup() {
    return {};
  },
});
</script>

 <style scoped>
.cls-1 {
  fill: none;
  stroke: #344154;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5px;
}
.cls-2 {
  fill: #687885;
}
.cls-3 {
  fill: #ff6d3b;
}
</style>
