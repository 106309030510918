<template>
  <nav class="fixed z-50 w-full bg-white top-0 shadow-small">
    <div class="px-6 h-16 flex justify-between">
      <Logo />
      <NavBarDesktopLinks
        class="d-print-none"
        :navigationLinks="navigationLinks"
      />
      <NavBarAuthLinks
        class="d-print-none"
        :isMobileMenuOpen="isMobileMenuOpen"
        @onMobileMenuToggle="onMobileMenuToggle"
      />
    </div>
    <!-- Mobile menu, show/hide based on menu state. -->
    <NavBarMobileLinks
      v-if="isMobileMenuOpen"
      @onClose="isMobileMenuOpen = false"
      :navigationLinks="navigationLinks"
      :isOpen="isMobileMenuOpen"
    />
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import Logo from "@/components/layout/navbar/Logo.vue";
import NavBarDesktopLinks from "@/components/layout/navbar/NavBarDesktopLinks.vue";
import NavBarAuthLinks from "@/components/layout/navbar/authlinks/NavBarAuthLinks.vue";
import { useRoute } from "vue-router";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { RouteName } from "@/router/types";
import NavBarMobileLinks from "@/components/layout/navbar/NavBarMobileLinks.vue";

export default defineComponent({
  components: {
    NavBarMobileLinks,
    NavBarAuthLinks,
    NavBarDesktopLinks,
    Logo,
  },
  setup() {
    const isMobileMenuOpen = ref(false);
    const onMobileMenuToggle = (state: boolean) =>
      (isMobileMenuOpen.value = state);
    const route = useRoute();
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipalitySlug = computed(() => municipalityState.municipality?.uid);
    const routeNames: { name: RouteName; title: string }[] = [
      { name: RouteName.KOOND_VAADE, title: "Koondvaade" },
      { name: RouteName.TULUD, title: "Tulud" },
      { name: RouteName.KULUD, title: "Kulud" },
      { name: RouteName.INVESTEERINGUD, title: "Investeeringud" },
    ];
    const navigationLinks = computed(() =>
      routeNames.map((link) => ({
        name: link.name,
        title: link.title,
        params: {
          ...route.params,
          municipalitySlug: municipalitySlug.value,
        },
      }))
    );

    watch(
      () => route.fullPath,
      () => onMobileMenuToggle(false)
    );

    return {
      isMobileMenuOpen,
      onMobileMenuToggle,
      navigationLinks,
    };
  },
});
</script>

<style scoped>
@media print {
  .d-print-none {
    display: none !important;
  }
}
</style>
