<template>
  <div class="space-x-12 2xl:space-x-20 hidden xl:flex">
    <router-link
      v-for="item of navigationLinks"
      :key="item.name"
      :to="{ name: item.name, params: item.params }"
      exact-active-class="border-primary-teal text-primary-teal"
      class="flex items-center px-1 border-b-3 font-medium text-base place-content-center border-transparent text-black tracking-wide hover:text-primary-teal transition duration-300 ease-in-out"
    >
      <component :is="icons[item.name]" />
      {{ item.title }}
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { RouteName } from "@/router/types";
import KoondvaadeIcon from "@/components/icons/KoondvaadeIcon.vue";
import KuludIcon from "@/components/icons/KuludIcon.vue";
import TuludIcon from "@/components/icons/TuludIcon.vue";
import InvesteeringudIcon from "@/components/icons/InvesteeringudIcon.vue";

export default defineComponent({
  components: {},
  name: "NavBarDesktopLinks",
  props: {
    navigationLinks: {
      type: Array as PropType<
        {
          name: RouteName;
          title: string;
          params: { municipalitySlug: string | null };
        }[]
      >,
      required: true,
    },
  },
  setup() {
    const icons = {
      [RouteName.KOOND_VAADE]: KoondvaadeIcon,
      [RouteName.TULUD]: TuludIcon,
      [RouteName.KULUD]: KuludIcon,
      [RouteName.INVESTEERINGUD]: InvesteeringudIcon,
    };
    return { icons };
  },
});
</script>

<style scoped></style>
