<template>
  <div class="relative sm:py-16 bg-white">
    <div class="max-w-7xl mx-auto lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div
          class="
            lg:z-10
            lg:col-start-1
            lg:row-start-1
            lg:col-span-4
            lg:py-16
            lg:bg-transparent
          "
        >
          <div class="inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true">
            <div class="bg-white">
              <div
                class="
                  max-w-7xl
                  mx-auto
                  text-center
                  py-12
                  px-4
                  sm:px-6
                  lg:py-16
                  lg:px-8
                "
              >
                <h2 class="text-3xl text-gray-900 sm:text-4xl">
                  <span class="block tracking-tight font-bold">{{
                    municipalityName
                  }}</span>
                  <span class="mt-2 font-normal block text-xl text-gray-700"
                    >Ei ole veel liitunud</span
                  >
                </h2>
                <div class="mt-8 flex justify-center">
                  <div class="inline-flex rounded-md shadow" v-if="!user">
                    <router-link
                      :to="{ name: RouteName.SIGN_UP }"
                      class="
                        inline-flex
                        items-center
                        justify-center
                        px-5
                        py-2.5
                        border border-transparent
                        text-base
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                      "
                    >
                      Liitu kohe
                    </router-link>
                  </div>
                  <div class="ml-3 inline-flex">
                    <router-link
                      :to="{ name: RouteName.HOME }"
                      class="
                        inline-flex
                        items-center
                        justify-center
                        px-5
                        py-2.5
                        border border-transparent
                        text-base
                        font-medium
                        rounded-md
                        text-indigo-700
                        bg-indigo-100
                        hover:bg-indigo-200
                      "
                    >
                      Pealehele
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              max-w-md
              mx-auto
              px-4
              sm:max-w-3xl
              sm:px-6
              lg:max-w-none
              lg:p-0
            "
          >
            <div
              class="
                aspect-w-10 aspect-h-6
                sm:aspect-w-2 sm:aspect-h-1
                lg:aspect-w-1
              "
            >
              <img
                class="object-cover object-center rounded-xl shadow-2xl h-112"
                src="../../assets/images/pold.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          class="
            hidden
            relative
            bg-white
            border-2
            lg:col-start-3
            lg:row-start-1
            lg:col-span-10
            lg:rounded-xl
            lg:grid lg:grid-cols-10
          "
        >
          <div
            class="hidden absolute inset-0 overflow-hidden rounded-xl lg:block"
            aria-hidden="true"
          >
            <svg
              class="
                absolute
                bottom-full
                left-full
                transform
                translate-y-1/3
                -translate-x-2/3
                xl:bottom-auto
                xl:top-0
                xl:translate-y-0
              "
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-indigo-500"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
            <svg
              class="
                absolute
                top-full
                transform
                -translate-y-1/3 -translate-x-1/3
                xl:-translate-y-1/2
              "
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-indigo-500"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>
          <div class="z-10 col-start-4 col-span-9 pt-24">
            <h2 class="text-3xl text-gray-900 sm:text-4xl">
              <span class="block tracking-tight font-bold">
                {{ municipalityName }}</span
              >
              <span class="mt-2 font-normal block text-xl text-gray-700"
                >Ei ole veel liitunud</span
              >
            </h2>
            <div class="mt-8 flex space-x-4">
              <div class="inline-flex rounded-md" v-if="!user">
                <router-link
                  :to="{
                    name: RouteName.SIGN_UP,
                    query: { omavalitsus: slug },
                  }"
                  class="
                    py-2.5
                    px-5
                    inline-flex
                    items-center
                    border border-indigo-600
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-indigo-600
                    hover:bg-transparent
                    hover:text-indigo-600
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600
                    transition
                    duration-300
                    ease-in-out
                  "
                >
                  Liitu kohe
                </router-link>
              </div>
              <div class="inline-flex">
                <router-link
                  :to="{ name: RouteName.HOME }"
                  class="
                    inline-flex
                    items-center
                    justify-center
                    px-5
                    py-2.5
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-indigo-600
                    bg-indigo-50
                    hover:bg-indigo-100
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    transition
                    duration-300
                    ease-in-out
                  "
                >
                  Tagasi pealehele
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { RouteName } from "@/router/types";
import { useUserStore } from "@/store/UserStore";

export default defineComponent({
  name: "NotRegistredMunicipality",
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const slug = computed(() => municipalityState.municipality?.uid);
    const municipalityName = computed(
      () => municipalityState.municipality?.name
    );

    return { municipalityName, user, RouteName, slug };
  },
});
</script>

<style scoped></style>
