<template>
  <div class="hidden flex-1 justify-end md:flex sm:items-center space-x-4">
    <router-link :to="{ name: RouteName.LOGIN }">
      <button
        class="
          flex
          justify-center
          items-center
          py-2.5
          px-4
          whitespace-nowrap
          border border-transparent
          text-sm
          font-semibold
          text-indigo-600
          bg-gray-100
          hover:bg-gray-200
          hover:bg-opacity-70
          rounded-md
          focus:outline-none
          transition
          duration-300
          ease-in-out
        "
      >
        Logi sisse
      </button>
    </router-link>
    <router-link :to="{ name: RouteName.SIGN_UP }">
      <div class="py-2.5 px-6 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-transparent hover:text-indigo-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 transition duration-300 ease-in-out">Liitu</div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "NavBarLoggedOutAuthLinks",
  setup() {
    return { RouteName };
  },
});
</script>

<style scoped></style>
