
import KoondvaadeIcon from "@/components/icons/KoondvaadeIcon.vue";
import KuludIcon from "@/components/icons/KuludIcon.vue";
import TuludIcon from "@/components/icons/TuludIcon.vue";
import InvesteeringudIcon from "@/components/icons/InvesteeringudIcon.vue";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from "vue";

import { RouteName } from "@/router/types";
import CustomButton from "@/components/basic/CustomButton.vue";
import { useUserStore } from "@/store/UserStore";

export default defineComponent({
  name: "NavBarMobileLinks",
  components: { CustomButton },
  props: {
    navigationLinks: {
      type: Array as PropType<{
        name: RouteName;
        title: string;
        params: { municipalitySlug: string | null };
      }[]>,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    onClose: () => true
  },
  setup() {
    const dropdownID = ref("mobile-menu");
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const icons = {
      [RouteName.KOOND_VAADE]: KoondvaadeIcon,
      [RouteName.TULUD]: TuludIcon,
      [RouteName.KULUD]: KuludIcon,
      [RouteName.INVESTEERINGUD]: InvesteeringudIcon
    };

    // TODO: buggy, wont work, don't know why! addEventListener fires right away
    // const handleDropDown = (e: MouseEvent) => {
    //   if (!props.isOpen) return;
    //   const target = e.target as HTMLDivElement;
    //   // if (!target?.closest(`#${dropdownID.value}`)) emit("onClose");
    // };
    //
    // onMounted(() => window.addEventListener("click", handleDropDown));
    // onBeforeUnmount(() => window.removeEventListener("click", handleDropDown));
    return { dropdownID, icons, RouteName, user };
  }
});
