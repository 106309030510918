
import { defineComponent } from "vue";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "NavBarLoggedOutAuthLinks",
  setup() {
    return { RouteName };
  },
});
