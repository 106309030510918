<template>
  <svg class="h-9 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.57 245.28">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Icon_Set" data-name="Icon Set">
        <polyline
          class="cls-1"
          points="145.16 206.5 163.82 206.5 133.29 242.53 102.77 206.5 121.43 206.5 121.43 164.82"
        />
        <path
          class="cls-1"
          d="M145,206.5V2.75H33.11a9.33,9.33,0,0,0-9.33,9.32V23.78H12.07a9.33,9.33,0,0,0-9.32,9.33V174.17H65.43"
        />
        <polyline
          class="cls-1"
          points="63.58 151.16 63.58 54.06 92.58 54.06 92.58 151.16"
        />
        <polyline class="cls-1" points="34.58 150.79 34.58 86.21 63.58 86.21" />
        <polyline
          class="cls-1"
          points="92.58 111.52 121.58 111.52 121.58 154.32"
        />
        <line class="cls-1" x1="73.88" y1="174.17" x2="73.88" y2="174.17" />
        <line class="cls-2" x1="86.52" y1="174.17" x2="105.54" y2="174.17" />
        <line class="cls-1" x1="111.88" y1="174.17" x2="111.88" y2="174.17" />
        <path
          class="cls-3"
          d="M33.77,23.64h0a11,11,0,0,1-11,11h-11V56.42a34.36,34.36,0,0,0,19,.86A34.75,34.75,0,0,0,56.41,31.67a34.36,34.36,0,0,0-.86-19H33.77Z"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "KuludIcon",
  setup() {
    return {};
  },
});
</script>

<style scoped>
.cls-1,
.cls-2 {
  fill: none;
  stroke: #344154;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5.5px;
}
.cls-2 {
  stroke-dasharray: 0.02 12.65;
}
.cls-3 {
  fill: #ff6d3b;
}
</style>