
import { computed, defineComponent } from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { RouteName } from "@/router/types";
import { useUserStore } from "@/store/UserStore";

export default defineComponent({
  name: "NotRegistredMunicipality",
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const slug = computed(() => municipalityState.municipality?.uid);
    const municipalityName = computed(
      () => municipalityState.municipality?.name
    );

    return { municipalityName, user, RouteName, slug };
  },
});
