
import { defineComponent } from "vue";
import DropdownMenu from "@/components/layout/DropdownMenu.vue";

export default defineComponent({
  name: "NavBarLoggedInUserAuthLinks",
  components: { DropdownMenu },
  setup() {
    return {};
  },
});
