<template>
  <svg class="h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.26 95.21">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M23.26,0V80h62V0Zm22,5h34V7h-34Zm0,10h23v2h-23Zm0,8h34v2h-34Zm0,10h23v2h-23Zm0,8h34v2h-34Zm-18-37h10V6h-8V16h10V11h2v7h-14Zm0,18h10v2h-8V34h10V29h2v7h-14Zm14,32h-14V40h10v2h-8V52h10V47h2Zm-6.53-4.15L30.6,45.72,32,44.31,34.73,47l7-7,1.42,1.42Zm0-18L30.6,27.72,32,26.31,34.73,29l7-7,1.42,1.42Zm0-18L30.6,9.72,32,8.31,34.73,11l7-7,1.42,1.42ZM45.26,51h23v2h-23Zm24,25a10,10,0,1,1,10-10A10,10,0,0,1,69.26,76Zm12-28h-36V46h36Zm0-18h-36V28h36Zm0-18h-36V10h36Z"
        />
        <path
          class="cls-1"
          d="M69.26,58a8,8,0,1,0,8,8A8,8,0,0,0,69.26,58ZM68,70.56l-4.59-5.51L65,63.77l3.1,3.73,5.39-6.06,1.49,1.33Z"
        />
        <polygon
          class="cls-2"
          points="87.26 12 87.26 14 94.26 14 94.26 91 37.7 91 32.26 92.74 31.44 93 32.26 93 96.26 93 96.26 12 87.26 12"
        />
        <path
          class="cls-2"
          d="M80,88.5a10.39,10.39,0,0,0,7.24-17.85v3.2A8.39,8.39,0,0,1,80,86.5,8.44,8.44,0,0,1,72.58,82h-2.2A10.45,10.45,0,0,0,80,88.5Z"
        />
        <rect class="cls-2" x="87.26" y="21" width="2" height="2" />
        <rect class="cls-2" x="87.26" y="26" width="4" height="2" />
        <rect class="cls-2" x="87.26" y="39" width="4" height="2" />
        <rect class="cls-2" x="87.26" y="44" width="2" height="2" />
        <rect class="cls-2" x="87.26" y="56" width="3" height="2" />
        <rect class="cls-2" x="87.26" y="61" width="4" height="2" />
        <polygon
          class="cls-2"
          points="19.93 27.45 21.26 31.6 21.26 27.02 19.93 27.45"
        />
        <polygon
          class="cls-2"
          points="8.92 25.73 16.11 23.44 15.5 21.53 6.41 24.44 10.66 37.74 21.26 34.35 21.26 32.25 11.96 35.23 8.92 25.73"
        />
        <polygon
          class="cls-2"
          points="16.15 45.55 21.26 48.18 21.26 44.01 20.48 45.53 17.07 43.77 16.15 45.55"
        />
        <polygon
          class="cls-2"
          points="21.26 55.58 16.81 57 21.06 70.3 21.26 70.24 21.26 64.35 19.32 58.3 21.26 57.68 21.26 55.58"
        />
        <polygon
          class="cls-2"
          points="59.31 82 25.82 92.69 22.41 82 21.26 78.39 2.51 19.71 21.26 13.72 21.26 11.62 0 18.41 24.53 95.21 31.44 93 32.26 92.74 37.7 91 65.88 82 59.31 82"
        />
        <polygon
          class="cls-2"
          points="21.26 31.6 21.26 32.25 21.45 32.19 21.26 31.6"
        />
        <polygon
          class="cls-2"
          points="21.26 21.22 19.8 20.47 15.27 29.25 11.87 27.49 10.95 29.27 16.14 31.94 21.26 22.01 21.58 21.39 21.26 21.22"
        />
        <polygon
          class="cls-2"
          points="21.31 39.72 21.26 39.55 20.7 37.81 11.61 40.72 15.86 54.02 21.26 52.3 21.26 50.2 17.16 51.51 14.12 42.02 21.26 39.74 21.31 39.72"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "KoondvaadeIcon",
  setup() {
    return {};
  },
});
</script>

 <style scoped>
.cls-1 {
  fill: #2f989f;
}
.cls-2 {
  fill: #3b4652;
}
</style>
