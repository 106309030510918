<template>
  <svg
    class="h-9 mr-2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 173.85 247.66"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Icon_Set" data-name="Icon Set">
        <polyline
          class="cls-1"
          points="65.37 230.62 65.37 130.16 94.43 130.16 94.43 230.62"
        />
        <polyline
          class="cls-1"
          points="36.32 230.25 36.32 162.37 65.37 162.37"
        />
        <polyline
          class="cls-1"
          points="94.43 187.73 123.48 187.73 123.48 230.25"
        />
        <path
          class="cls-1"
          d="M84.29,65.75H34.48a9.74,9.74,0,0,0-9.75,9.74V87.73H12.5a9.74,9.74,0,0,0-9.75,9.74V244.91H151.59V40.41H171.1L139.2,2.75,107.28,40.41h19.51V52.64"
        />
        <line class="cls-1" x1="96.95" y1="65.75" x2="96.95" y2="65.75" />
        <line class="cls-2" x1="106.86" y1="65.75" x2="131.7" y2="65.75" />
        <line class="cls-1" x1="136.67" y1="65.75" x2="136.67" y2="65.75" />
        <path
          class="cls-3"
          d="M35.73,87.73h0a11,11,0,0,1-11,11h-11V120.5a34.32,34.32,0,0,0,19,.86,34.69,34.69,0,0,0,25.61-25.6,34.18,34.18,0,0,0-.86-19H35.73Z"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TuludIcon",
  setup() {
    return {};
  },
});
</script>

<style scoped>
.cls-1,
.cls-2 {
  fill: none;
  stroke: #344154;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5.5px;
}
.cls-2 {
  stroke-dasharray: 0.02 9.91;
}
.cls-3 {
  fill: #17c2c9;
}
</style>