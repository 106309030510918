
import { defineComponent, PropType } from "vue";

import { RouteName } from "@/router/types";
import KoondvaadeIcon from "@/components/icons/KoondvaadeIcon.vue";
import KuludIcon from "@/components/icons/KuludIcon.vue";
import TuludIcon from "@/components/icons/TuludIcon.vue";
import InvesteeringudIcon from "@/components/icons/InvesteeringudIcon.vue";

export default defineComponent({
  components: {},
  name: "NavBarDesktopLinks",
  props: {
    navigationLinks: {
      type: Array as PropType<
        {
          name: RouteName;
          title: string;
          params: { municipalitySlug: string | null };
        }[]
      >,
      required: true,
    },
  },
  setup() {
    const icons = {
      [RouteName.KOOND_VAADE]: KoondvaadeIcon,
      [RouteName.TULUD]: TuludIcon,
      [RouteName.KULUD]: KuludIcon,
      [RouteName.INVESTEERINGUD]: InvesteeringudIcon,
    };
    return { icons };
  },
});
