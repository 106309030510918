
import { computed, defineComponent} from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipalitySlug = computed(
      () => municipalityState.municipality?.uid
    );
    const koondVaadePath = computed(
      () => `/${municipalitySlug.value}/koondvaade`
    );

    const toKoondVaade = () => router.push({ path: koondVaadePath.value });

    const logoURL = computed(() => municipalityState.logoUrl);
    return { koondVaadePath, logoURL, toKoondVaade };
  },
});
