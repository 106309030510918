
import { defineComponent } from "vue";
import { currentUser } from "@/firebase/firebase";
import NavBarLoggedInUserAuthLinks from "@/components/layout/navbar/authlinks/NavBarLoggedInUserAuthLinks.vue";
import NavBarLoggedOutAuthLinks from "@/components/layout/navbar/authlinks/NavBarLoggedOutAuthLinks.vue";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "NavBarAuthLinks",
  components: { NavBarLoggedOutAuthLinks, NavBarLoggedInUserAuthLinks },
  props: {
    isMobileMenuOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    onMobileMenuToggle: (state: unknown) => typeof state === "boolean",
  },
  setup(props, { emit }) {
    const toggleMobileMenu = () =>
      emit("onMobileMenuToggle", !props.isMobileMenuOpen);

    return { currentUser, toggleMobileMenu, RouteName };
  },
});
