<template>
  <button
    :type="type"
    :disabled="disabledButton"
    :class="[
      'justify-center items-center py-2.5 px-5 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-transparent hover:text-indigo-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 transition duration-300 ease-in-out',
      {
        '': !disabledButton,
        'bg-gray-300': disabledButton,
      },
    ]"
  >
    <LoadingSpinner v-if="loading" class="animate-spin h-4 w-4 mr-1" />
    <span class="h-4 leading-4">
      <slot></slot>
    </span>
  </button>
</template>

<script>
import { computed, defineComponent } from "vue";
import LoadingSpinner from "@/components/basic/LoadingSpinner.vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingSpinner,
  },
  setup(props) {
    const disabledButton = computed(() => {
      return props.disabled || props.loading;
    });

    return {
      disabledButton,
    };
  },
});
</script>
