<template>
  <div class="xl:hidden border-t border-gray-200" :id="dropdownID">
    <div class="py-6">
      <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
      <router-link
        v-for="item of navigationLinks"
        :key="item.name"
        :to="{ name: item.name, params: item.params }"
        exact-active-class="text-primary-teal"
        class="block items-center h-full py-3 font-medium text-base place-content-center border-transparent text-black tracking-wide hover:text-primary-teal hover:bg-gray-50 transition duration-300 ease-in-out pl-16"
      >
        <div class="flex items-center">
          <div class="w-12">
            <component :is="icons[item.name]" />
          </div>
          {{ item.title }}
        </div>
      </router-link>

      <div class="mt-6 px-6 md:hidden w-full inline-flex" v-if="!user">
        <div class="w-full inline-flex">
          <router-link class="flex w-1/2 px-2" :to="{ name: RouteName.LOGIN }">
            <button
              class="flex w-full justify-center items-center py-2.5 px-5 whitespace-nowrap border border-transparent text-sm font-semibold text-indigo-600 bg-gray-100 hover:bg-gray-200 hover:bg-opacity-70 rounded-md focus:outline-none transition duration-300 ease-in-out"
            >
              Logi sisse
            </button>
          </router-link>
          <router-link
            class="flex w-1/2 px-2"
            :to="{ name: RouteName.SIGN_UP }"
          >
            <CustomButton class="w-full" type="submit"
            >Liitu
            </CustomButton
            >
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import KoondvaadeIcon from "@/components/icons/KoondvaadeIcon.vue";
import KuludIcon from "@/components/icons/KuludIcon.vue";
import TuludIcon from "@/components/icons/TuludIcon.vue";
import InvesteeringudIcon from "@/components/icons/InvesteeringudIcon.vue";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from "vue";

import { RouteName } from "@/router/types";
import CustomButton from "@/components/basic/CustomButton.vue";
import { useUserStore } from "@/store/UserStore";

export default defineComponent({
  name: "NavBarMobileLinks",
  components: { CustomButton },
  props: {
    navigationLinks: {
      type: Array as PropType<{
        name: RouteName;
        title: string;
        params: { municipalitySlug: string | null };
      }[]>,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    onClose: () => true
  },
  setup() {
    const dropdownID = ref("mobile-menu");
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const icons = {
      [RouteName.KOOND_VAADE]: KoondvaadeIcon,
      [RouteName.TULUD]: TuludIcon,
      [RouteName.KULUD]: KuludIcon,
      [RouteName.INVESTEERINGUD]: InvesteeringudIcon
    };

    // TODO: buggy, wont work, don't know why! addEventListener fires right away
    // const handleDropDown = (e: MouseEvent) => {
    //   if (!props.isOpen) return;
    //   const target = e.target as HTMLDivElement;
    //   // if (!target?.closest(`#${dropdownID.value}`)) emit("onClose");
    // };
    //
    // onMounted(() => window.addEventListener("click", handleDropDown));
    // onBeforeUnmount(() => window.removeEventListener("click", handleDropDown));
    return { dropdownID, icons, RouteName, user };
  }
});
</script>

<style scoped></style>
