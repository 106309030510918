<template>
  <div class="flex-1 justify-end sm:ml-6 sm:flex sm:items-center">
    <DropdownMenu />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DropdownMenu from "@/components/layout/DropdownMenu.vue";

export default defineComponent({
  name: "NavBarLoggedInUserAuthLinks",
  components: { DropdownMenu },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
